import * as React from "react"
import { useState } from "react"

import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Dropzone from "react-dropzone"
import { StaticImage } from "gatsby-plugin-image"
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

export default function NewSupervisedPage() {

    const [email, setEmail] = useState("") // '' is the initial state value
    const [file, setFile] = useState("") // '' is the initial state value
    const [isError, setIsError] = useState(false) // '' is the initial state value
    const [errorMsg, setErrorMsg] = useState("") // '' is the initial state value
  
    function hide() {
      document.getElementById("tohide").style.display = "none"
      document.getElementById("myProgress").style.display = "block"
      move()
    }
  
    let i = 0
    function move() {
      if (i === 0) {
        i = 1
        let elem = document.getElementById("myBar")
        let width = 1
        let id = setInterval(frame, 10)
        function frame() {
          if (width >= 100) {
            clearInterval(id)
            document.getElementById("fileRow").style.display = "none"
            document.getElementById("emailRow").style.display = "block"
            i = 0
          } else {
            width++
            elem.style.width = width + "%"
          }
        }
      }
    }
  
    function retry() {
      setIsError(false)
      document.getElementById("emailRow").style.display = "none"
      document.getElementById("myProgress").style.display = "none"
      document.getElementById("fileRow").style.display = "block"
      document.getElementById("tohide").style.display = "block"
    }
  
    function sendSupervised() {
      var formData = new FormData()
      formData.append("file", file)
      formData.append("email", email)
  
      fetch(`https://www.dataalp.com/supervised`, {
        method: "POST",
        body: formData,
      })
        .then(res => res.json())
        .then(res => {
          if (!res.success) {
            
            setIsError(true)
            setErrorMsg(res.issue);
          } else {
            setTimeout(() => {
              window.location.replace(`https://www.dataalp.com/${res.redirect}`)
            }, 1000)
          }
        })
        .catch(err => console.log(err))
    }


  return (
    <Layout>
      <Seo title="Supervised" />
      <Navbar />

      <div id="supervised">
      <section id="hero">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 mx-auto">
                <div data-aos="zoom-out">
                  <h1 className="fs-2 text-center" style={{ color: "#FFFFFF" }}>
                    Upload Supervised Learning Dataset
                    <span></span>
                  </h1>
                  <h6
                    style={{
                      color: "#FFFFFF",
                      textAlign: "justify",
                      lineHeight: "1.5",
                      letterSpacing: "0.2px",
                    }}
                  >
                    Upload your data in .csv format and make sure all columns
                    have a header, with the target/label/output column named as
                    'tar'. If the target/label/output column is not named as
                    'tar', the algorithm will treat this dataset for
                    unsupervised learning. The story of your dataset will be
                    emailed to you as a DataAlp Report within few seconds.
                  </h6>
                </div>
              </div>
            </div>

            <div id="fileRow" className="row">
              <div
                className="col-lg-12 pt-lg-0 order-2 order-lg-1 mx-auto"
                style={{ paddingTop: "28px" }}
              >
                <Dropzone
                  onDrop={acceptedFiles => {
                    setFile(acceptedFiles[0])
                    hide()
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div
                          className="card p-5"
                          style={{ borderRadius: "30px" }}
                        >
                          <div
                            className="input-container p-5"
                            style={{
                              backgroundImage:
                                "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='3' stroke-dasharray='2%2c 2%2c 2%2c 11' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e\")",
                              borderRadius: "10px",
                            }}
                          >
                            <div id="tohide">
                              <div className="d-flex justify-content-center">
                                <StaticImage
                                  src="../images/sheets.png"
                                  alt=""
                                  placeholder="none"
                                  width={100}
                                />
                              </div>
                              <h6 className="text-center fw-bold mt-3">
                                Drop your file here, or{" "}
                                <span style={{ color: "blue" }}>browse</span>
                              </h6>
                              <span
                                className="d-block text-center fw-bold"
                                style={{
                                  fontSize: "0.9rem",
                                  opacity: "0.5",
                                }}
                              >
                                Supports: .CSV
                              </span>
                            </div>

                            <div id="myProgress">
                              <div id="myBar"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>

            <div id="emailRow" className="row">
              <div
                className="col-lg-12 pt-lg-0 order-2 order-lg-1 mx-auto"
                style={{ paddingTop: "28px" }}
              >
                <section>
                  <div>
                    <div className="card p-5" style={{ borderRadius: "30px" }}>
                      <div
                        className="input-container p-4"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <StaticImage
                            src="../images/email.svg"
                            alt=""
                            placeholder="none"
                            quality={100}
                            width={100}
                          />
                        </div>
                        <div className="mt-4">
                          <input
                            type="email"
                            className="form-control mx-auto"
                            name="email"
                            id="email"
                            value={email}
                            style={{ width: "40%" }}
                            onChange={e => setEmail(e.target.value)}
                          ></input>
                        </div>
                        <h6 className="text-center fw-bold mt-3">
                          Enter your email address
                        </h6>
                        <div className="text-center mt-3">
                          <button
                            type="submit"
                            style={{
                              backgroundColor: "#a435f0",
                              color: "white",
                              borderRadius: "100px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              border: "0px",
                            }}
                            onClick={sendSupervised}
                          >
                            Send Report
                          </button>
                        </div>
                        <div className="mt-4">
                          {
                            isError && <Alert
                            variant="danger"
                            onClose={() => setIsError(false)}
                            dismissible
                          >
                            <Alert.Heading>
                              Oh snap! You got an error!
                            </Alert.Heading>
                            <p>
                              {errorMsg}
                            </p>
                            <hr />
                            <div className="d-flex justify-content-end">
                              <Button onClick={() => retry()} variant="outline-danger">
                                Try Again
                              </Button>
                            </div>
                          </Alert>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

      <Footer />
    </Layout>
  )
}
